@use 'sass:math';
@import '/src/scss/mixins';
@import '/src/scss/style.scss';

.products {
  margin: rem(150) 0 0;

  @media (max-width: $tablet) {
    margin: rem(30) 0 0;
    padding: rem(30) 0 0;
  }

  &Body {
    display: flex;
    justify-content: space-between;

    @media (max-width: $tablet) {
      flex-direction: column;
      gap: rem(20);
    }

    &__divider {
      flex: 0 0 auto;
      min-height: 100%;
      width: rem(1);
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(65, 65, 65, 1) 25%,
        rgba(105, 105, 105, 1) 50%,
        rgba(105, 105, 105, 1) 75%,
        rgba(0, 0, 0, 1) 98%
      );

      @media (max-width: $tablet) {
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(65, 65, 65, 1) 25%,
          rgba(105, 105, 105, 1) 50%,
          rgba(105, 105, 105, 1) 75%,
          rgba(0, 0, 0, 1) 98%
        );
        width: calc(100% + rem(80));
        height: rem(1);
        min-height: auto;
        margin: 0 0 0 rem(-40);
      }
    }
    &__item {
      padding: rem(135) 0;
      max-width: rem(315);

      @media (max-width: $tablet) {
        padding: 0;
        max-width: 100%;
      }
    }
  }
}
