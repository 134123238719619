@use 'sass:math';
@import '/src/scss/mixins';
@import '/src/scss/style';

.pagination {
  position: fixed;
  z-index: 9;
  top: 50%;
  transform: translate(0, -50%);
  left: rem(60);

  display: flex;
  gap: rem(48);
  flex-direction: column;

  @media (max-width: $tablet) {
    display: none;
  }

  &__item {
    position: relative;
    display: flex;
    width: rem(24);
    height: rem(24);

    &:first-child {
      &::before {
        display: none;
      }
    }

    &::before {
      content: '';
      position: absolute;
      left: rem(7.5);
      bottom: calc(100% + rem(3));
      height: rem(50);
      width: rem(1);
      background-color: #fff;
    }

    &.active {
      span {
        &::after {
          transform: translate(-50%, -50%) scale(1);
          opacity: 1;
        }
      }
    }

    span {
      position: relative;
      width: rem(16);
      height: rem(16);
      border: rem(1) solid #fff;
      transform: rotate(45deg);

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: rem(8);
        height: rem(8);
        background-color: #fff;

        transform: translate(-50%, -50%) scale(0);
        opacity: 0;
        transition: all $time $tr;
      }
    }

    &.active {
    }
  }
}
