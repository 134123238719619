@use 'sass:math';
@import '/src/scss/mixins';
@import '/src/scss/style';

.anime {
  position: relative;
  margin: rem(110) 0 0;
  padding: rem(60) 0 0;

  @media (max-width: $tablet) {
    margin: rem(65) 0 0;
    padding: 0;
  }

  &::after {
    content: '';
    position: absolute;
    width: 100vw;
    top: 0;
    height: rem(1);
    background: linear-gradient(
      90deg,
      rgba(105, 105, 105, 0) 0%,
      #696969 50%,
      rgba(105, 105, 105, 0) 100%
    );

    @media (max-width: $tablet) {
      display: none;
    }
  }

  .con {
    padding: 0;
  }

  &Body {
    &__title {
      font-family: 'DragonSlapper', sans-serif;
      font-weight: 400;
      font-size: rem(40);
      text-transform: uppercase;
      text-align: center;
      color: #fff;

      @media (max-width: $tablet) {
        font-size: rem(24);
      }
    }

    &__video {
      position: relative;
      margin: rem(60) 0 0;

      @media (max-width: $tablet) {
        margin: rem(20) 0 0;
      }

      video {
        width: 100%;
        height: 100%;
      }

      &Play {
        cursor: pointer;
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: rem(74);
        height: rem(74);

        transition: all $time $tr;

        @media (max-width: $tablet) {
          width: rem(25);
          height: rem(25);
        }

        &:hover {
          opacity: 0.8;
        }

        svg {
          width: 100%;
          height: 100%;
        }

        &.hidden {
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
        }
      }
    }
  }
}
