@use 'sass:math';
@import '/src/scss/mixins';
@import '/src/scss/style';

.header {
  position: fixed;
  z-index: 11;
  left: 50%;
  top: rem(20);
  transform: translate(-50%, 0);

  transition: all $time $tr;

  @media (max-width: $tablet) {
    // display: none;
    width: 100%;
    left: 0;
    transform: translate(0);
  }

  .con {
    width: 100%;
  }

  &.scrolled {
    transform: translate(-50%, -140%);

    @media (max-width: $tablet) {
      transform: translate(0);
    }

    &.visible {
      transform: translate(-50%, 0);

      @media (max-width: $tablet) {
        transform: translate(0);
      }

      &.open {
        transition: none;
        backdrop-filter: none;
        transform: none;
      }
    }
  }

  &Body {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: rem(775);
    height: rem(60);

    @media (max-width: $tablet) {
      width: 100%;
      height: auto;
      justify-content: flex-end;
    }

    &__bg {
      position: absolute;
      inset: 0;

      @media (max-width: $tablet) {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    ul {
      position: relative;
      // background-color: rgba($color: #000000, $alpha: 0.48);
      // padding: 0 rem(2);
      // margin: rem(1) 0;

      display: flex;
      gap: rem(25);
      align-items: center;

      @media (max-width: $tablet) {
        display: none;
      }

      // &::before,
      // &::after {
      //   content: '';
      //   height: rem(1);
      //   position: absolute;
      //   left: 0;
      //   right: 0;
      //   background: linear-gradient(
      //     90deg,
      //     rgba(255, 255, 255, 1) 0%,
      //     rgba(255, 255, 255, 0) 100%
      //   );
      // }

      // &::before {
      //   top: rem(-1);
      // }
      // &::after {
      //   bottom: rem(-1);
      // }

      li {
        // &.active {
        //   a,
        //   div {
        //     font-weight: 700;
        //   }
        // }
        a,
        div {
          cursor: pointer;
          font-family: 'Open Sans', sans-serif;

          font-weight: 400;
          font-size: rem(16);
          text-transform: uppercase;
          text-align: center;
          color: #fff;

          transition: all $time $tr;

          &:hover {
            font-weight: 700;
            // opacity: 0.7;
          }
        }
      }
    }

    &__burger {
      display: none;
      justify-content: center;
      align-items: center;
      width: rem(28);
      height: rem(28);
      border-radius: 50%;
      backdrop-filter: blur(15.590587615966797px);
      background-color: rgba($color: #000000, $alpha: 0.48);

      @media (max-width: $tablet) {
        display: flex;
      }

      &.open {
        svg {
          transform: rotate(45deg);
        }
      }

      svg {
        width: rem(16);
        height: rem(16);
        transition: all $time $tr;
      }
    }

    &__elem {
      &:first-child {
        // transform: translate(rem(1), 0);
      }
      &:last-child {
        // transform: translate(rem(-0.09), 0);
      }
    }
  }
}

.menu {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  padding: rem(60) 0 rem(25);
  backdrop-filter: blur(24px);
  background: rgba(0, 0, 0, 0.48);
  border-bottom: 1px solid #fff;
  transform: translate(0, -100%);
  transition: all $time $tr;
  pointer-events: none;
  user-select: none;
  visibility: hidden;

  &.open {
    pointer-events: unset;
    user-select: unset;
    visibility: visible;
    transform: translate(0);
  }

  &List {
    display: flex;
    gap: rem(10);
    flex-direction: column;
    align-items: center;

    a,
    div {
      font-family: 'BL Melody', sans-serif;
      font-weight: 500;
      font-size: rem(16);
      text-transform: uppercase;
      color: #fff;
    }
  }
  &Social {
    margin: rem(100) 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(20);

    a {
      width: rem(20);
      height: rem(20);

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}
