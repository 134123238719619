@use 'sass:math';
@import '/src/scss/mixins';
@import '/src/scss/style.scss';

.about {
  position: relative;
  margin: 0;
  padding: rem(160) 0 0;

  @media (max-width: $tablet) {
    margin: rem(60) 0 0;
    padding: 0;
  }

  &::after {
    content: '';
    position: absolute;
    width: 100vw;
    top: 0;
    height: rem(1);
    background: linear-gradient(
      90deg,
      rgba(105, 105, 105, 0) 0%,
      #696969 50%,
      rgba(105, 105, 105, 0) 100%
    );

    @media (max-width: $tablet) {
      display: none;
    }
  }

  &Body {
    &__title {
      font-family: 'DragonSlapper', sans-serif;
      font-weight: 400;
      font-size: rem(40);
      line-height: normal;
      text-align: center;
      color: #fff;

      @media (max-width: $tablet) {
        font-size: rem(24);
      }

      span {
        color: #ecaa2b;
      }
    }
    &__text {
      font-family: 'Open Sans', sans-serif;
      margin: rem(40) auto 0;
      max-width: rem(520);
      font-weight: 400;
      font-size: rem(20);
      line-height: normal;
      text-align: center;
      color: #9d9d9d;

      @media (max-width: $tablet) {
        max-width: rem(250);
        margin: rem(20) auto 0;
        font-size: rem(12);
      }
    }
    &__video {
      position: relative;
      margin: rem(40) auto 0;
      background: #000;

      width: rem(894);
      height: auto;
      // height: rem(500);

      @media (max-width: $tablet) {
        width: 100%;
        margin: rem(20) 0 0 rem(-16);
        width: calc(100% + rem(32));
        // transform: translate(rem(-32), 0);
        // height: rem(300);
      }

      video {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &::before {
        content: '';
        position: absolute;
        z-index: -1;
        inset: rem(-1);
        background: linear-gradient(
          180deg,
          rgba(105, 105, 105, 1) 0%,
          rgba(0, 0, 0, 0) 98%
        );
      }
    }
    &__link {
      position: relative;
      width: rem(260);
      height: rem(60);
      margin: rem(40) auto 0;
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      font-size: rem(16);
      text-transform: uppercase;
      text-align: center;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: $tablet) {
        margin: rem(30) auto 0;
        font-size: rem(12);
        width: rem(172);
        height: rem(38);
      }

      svg {
        position: absolute;
        z-index: -1;
        inset: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
