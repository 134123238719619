@use 'sass:math';
@import '/src/scss/mixins';
@import '/src/scss/style.scss';

.main {
  position: relative;
  z-index: 5;

  &__video {
    position: absolute;
    z-index: 0;
    inset: 0;

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      z-index: 3;
      background: rgba(0, 0, 0, 0.48);
    }

    &::before {
      content: '';
      position: absolute;
      z-index: 4;
      bottom: 0;
      left: 0;
      right: 0;
      height: rem(450);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 81%
      );
    }

    img,
    video {
      position: relative;
      z-index: 2;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    img {
      position: absolute;
      z-index: 1;
      inset: 0;
    }
  }

  .con {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
  }

  &Body {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__social {
      position: fixed;
      z-index: 2;
      top: 50%;
      right: rem(60);
      transform: translate(-50%, 0);
      display: flex;
      flex-direction: column;
      gap: rem(20);

      @media (max-width: $tablet) {
        display: none;
      }

      &Item {
        width: rem(40);
        height: rem(40);
        transition: all $time $tr;

        &:hover {
          opacity: 0.7;
        }

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    &__title {
      font-family: 'DragonSlapper', sans-serif;
      font-weight: 400;
      font-size: rem(128);
      text-transform: uppercase;
      text-align: center;
      color: #fff;
      margin: auto 0 0;

      @media (max-width: $tablet) {
        font-size: rem(48);
        // margin: auto 0;
      }
    }
    &__text {
      font-family: 'BL Melody', sans-serif;
      font-weight: 400;
      font-size: rem(20);
      text-align: center;
      line-height: normal;
      color: rgba(255, 255, 255, 1);
      max-width: rem(516);
      margin: rem(25) 0 auto;

      @media (max-width: $tablet) {
        font-size: rem(12);
        max-width: rem(272);
        // display: none;
      }
    }
    &__icon {
      position: absolute;
      left: 50%;
      bottom: rem(30);
      transform: translate(-50%, 0);
      width: rem(20);
      height: rem(44);
      animation: animIcon 1.5s infinite alternate-reverse;

      @media (max-width: $tablet) {
        display: none;
      }

      @keyframes animIcon {
        0% {
          transform: translate(-50%, 0);
        }
        100% {
          transform: translate(-50%, rem(10));
        }
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  &Telegram {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: rem(15);
    padding: rem(70) 0;

    &::after {
      content: '';
      position: absolute;
      width: 100vw;
      bottom: rem(30);
      height: rem(1);
      background: linear-gradient(
        90deg,
        rgba(105, 105, 105, 0) 0%,
        #696969 50%,
        rgba(105, 105, 105, 0) 100%
      );
    }

    &__icon {
      width: rem(30);
      height: rem(30);

      svg {
        width: 100%;
        height: 100%;
      }
    }
    &__text {
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      font-size: rem(16);
      text-transform: uppercase;
      text-align: center;
      color: #fff;
    }
  }
}
