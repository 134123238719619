@use 'sass:math';

// @import './locomotive-scroll.scss';

// Подключения миксинов ===========================================================================================================================================================================================================================================================================
@import './mixins.scss';

// Подключения шрифтов ===========================================================================================================================================================================================================================================================================
//&display=swap - додати при підключенні через плагін

// Подключить, если есть локальные файлы шрифтов
@import './fonts.scss';

// ============================================================================================================================================================================================================================================================================================================================================================================================
// Настройка шаблону ============================================================================================================================================================================================================================================================================================================================================================================================
// ============================================================================================================================================================================================================================================================================================================================================================================================

// Шрифт по умолчанию ==============================================================================================================================================================================================================================================================================
$fontFamily: 'Inter', sans-serif, -apple-system, BlinkMacSystemFont, Roboto,
  Ubuntu, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji';
$fontSize: rem(14);

// Основной цвет
$mainColor: cl(primary);

// Цвета
@import './colors-palette';

// Сетка  ===============================================================================================================================================================================================================================================================================================

// Минимальная ширина страницы
$minWidth: 320;
// Ширина полотна (макета)
$maxWidth: 1920;
// Ширина ограничивающего контейнера (0 = нет ограничения)
$maxWidthContainer: 1360;
// Загальний відступ у контейнера
// (30 = по 15px ліворуч і праворуч, 0 = немає відступу)
$containerPadding: 80;

// Ширина спрацьовування першого брейкпоінту
$containerWidth: $maxWidthContainer + $containerPadding;

// Брейк-поїнти
// $pc: em($containerWidth); // ПК, ноутбуки
$pc: em(1440); // ПК, ноутбуки
$tablet: em(991.98); // Планшети
$mobile: em(767.98); // Телефоны L
$mobileSmall: em(479.98); // Телефоны S
$time: 0.3s; // Тайминг на анимацию transition
$tr: ease-out; // Тип на анимации на transition
// ============================================================================================================================================================================================================================================================================================================================================================================================
// ============================================================================================================================================================================================================================================================================================================================================================================================

// Обнуленние ============================================================================================================================================================================================== =============================================================================================== ===============================================================================================
@import './null';

// Стили тега BODY ============================================================================================================================================================================================================================================================================================================================================================================================
html {
  // font-size: 0.836vw;

  // @media (max-width: $tablet) {
  //   font-size: 4.376vw;
  // }
}

body {
  /* background-color: cl(bkg-main); */
  background-color: #000000;

  .lock & {
    overflow: hidden;
    touch-action: none;
    overscroll-behavior: none;
  }

  &._lock {
    overflow: hidden;
    touch-action: none;
    overscroll-behavior: none;
  }

  .loaded & {
  }
  &::-webkit-scrollbar {
    height: rem(0);
    width: rem(0);
  }
  &::-webkit-scrollbar-track {
    /* background: cl(scroll-bg); */
  }
  &::-webkit-scrollbar-thumb {
    border-radius: rem(10);
    /* background: cl(alt); */
  }
}

// Оболочка wrapper ============================================================================================================================================================================================================================================================================================================================================================================================================================================

.scroll-container {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @supports (overflow: clip) {
    overflow: clip;
  }
  // Прижимаем
  > main {
    flex: 1 1 auto;
  }
  // Фикс для слайдеров
  > * {
    min-width: 0;
  }
}
::selection {
  /* color: cl(primary);
  background: cl(bg); */
}
// ======================================================================================================================================================================================================================

[class*='__container'] {
  max-width: calc(rem($maxWidthContainer));
  width: 100%;
  box-sizing: border-box;
  padding: 0 rem(40);
  margin: 0 auto;
  @media (max-width: $tablet) {
    padding: 0 rem(12);
    /* padding: 0 rem(15); */
  }
}
