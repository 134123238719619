@use 'sass:math';
@import '/src/scss/mixins';
@import '/src/scss/style.scss';

.card {
  display: flex;
  flex-direction: column;
  gap: rem(30);

  @media (max-width: $tablet) {
    gap: rem(20);
  }

  &__title {
    font-family: 'IM FELL French Canon SC', sans-serif;
    font-weight: 400;
    font-size: rem(32);
    line-height: 100%;
    text-align: center;
    color: #fff;

    @media (max-width: $tablet) {
      font-size: rem(24);
    }
  }
  &__img {
    display: none;
    background: #454545;

    @media (max-width: $tablet) {
      display: block;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__text {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: rem(16);
    text-align: center;
    color: #9d9d9d;
  }
  &__link {
    cursor: pointer;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: rem(16);
    text-transform: uppercase;
    text-align: center;
    color: #fff;
  }
}
