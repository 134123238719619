@use 'sass:math';
@import '/src/scss/mixins';
@import '/src/scss/style.scss';

.team {
  margin: rem(115) 0 0;

  @media (max-width: $tablet) {
    margin: rem(60) 0 0;
  }

  &__title {
    font-family: 'DragonSlapper', sans-serif;
    font-weight: 400;
    font-size: rem(40);
    line-height: normal;
    text-align: center;
    color: #fff;

    @media (max-width: $tablet) {
      font-size: rem(24);
    }
  }
  &Body {
    margin: rem(60) 0 0;
    display: flex;
    flex-wrap: wrap;
    gap: rem(60) rem(30);

    @media (max-width: $mobile) {
      margin: rem(24) 0 0;
      gap: rem(25) rem(15);
    }
  }
}

.card {
  flex: 0 0 calc(33.333% - rem(20));
  position: relative;

  @media (max-width: $mobile) {
    flex: 0 0 calc(50% - rem(7.5));
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
  }

  &::before {
    z-index: -1;
    inset: 0;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
  &::after {
    z-index: 0;
    top: rem(1);
    left: rem(1);
    right: rem(1);
    bottom: rem(0);
    background-color: #000;
  }

  &__img {
    position: relative;
    z-index: 1;
    padding: 0 0 100%;
    margin: rem(1);

    img {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &Content {
    position: relative;
    z-index: 1;
    padding: rem(14) rem(26) rem(45);

    &::after {
      content: '';
      pointer-events: none;
      position: absolute;
      z-index: -1;
      height: rem(1);
      left: rem(0);
      right: rem(0);
      bottom: rem(0);
      background-color: #000;
    }

    @media (max-width: $tablet) {
      padding: rem(8) rem(12) rem(14);
    }

    &__name {
      font-family: 'BL Melody', sans-serif;
      font-weight: 500;
      font-size: rem(24);
      text-transform: uppercase;
      color: #fff;

      @media (max-width: $tablet) {
        font-size: rem(10);
      }
    }
    &__pos {
      margin: rem(14) 0 0;
      font-family: 'BL Melody', sans-serif;
      font-weight: 400;
      font-size: rem(16);
      color: #bfbfbf;

      @media (max-width: $tablet) {
        margin: rem(5) 0 0;
        font-size: rem(8);
      }
    }
    &__text {
      margin: rem(14) 0 0;
      font-family: 'BL Melody', sans-serif;
      font-weight: 400;
      font-size: rem(16);
      color: #bfbfbf;
      @media (max-width: $tablet) {
        margin: rem(10) 0 0;
        font-size: rem(8);
      }
    }
  }
}
