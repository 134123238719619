@use 'sass:math';
@import '/src/scss/mixins';
@import '/src/scss/style.scss';

.partners {
  position: relative;
  z-index: 1;
  margin: rem(170) 0 0;

  @media (max-width: $tablet) {
    margin: rem(40) 0 0;
  }

  &__title {
    font-family: 'DragonSlapper', sans-serif;
    font-weight: 400;
    font-size: rem(40);
    line-height: normal;
    text-align: center;
    color: #fff;

    @media (max-width: $tablet) {
      font-size: rem(24);
    }
  }

  &Body {
    margin: rem(40) auto 0;
    max-width: rem(960);
    gap: rem(60) rem(28);
    display: flex;
    flex-wrap: wrap;

    @media (max-width: $tablet) {
      gap: 0;
      max-width: rem(280);
    }

    &__item {
      flex: 0 0 calc(25% - rem(21));
      position: relative;
      z-index: 1;
      padding: rem(30);
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: $tablet) {
        flex: 0 0 25%;
        padding: rem(8) rem(5);
      }

      &::before,
      &::after {
        content: '';
        position: absolute;

        @media (max-width: $tablet) {
          display: none;
        }
      }

      &::before {
        z-index: -1;
        inset: 0;
        background: linear-gradient(
          0deg,
          rgba(105, 105, 105, 0) 0%,
          rgba(105, 105, 105, 1) 100%
        );
      }
      &::after {
        z-index: 0;
        top: rem(1);
        left: rem(1);
        right: rem(1);
        bottom: 0;
        background-color: #000;
      }

      img {
        position: relative;
        z-index: 2;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
