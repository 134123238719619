@use 'sass:math';
@import '/src/scss/mixins';
@import '/src/scss/style';

.collection {
  position: relative;
  z-index: 1;
  margin: rem(100) 0 0;

  @media (max-width: $tablet) {
    margin: rem(60) 0 0;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    z-index: 8;
    display: block;
    top: 0;
    bottom: 0;
    width: 30vw;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    );

    @media (max-width: $tablet) {
      display: none;
    }
  }

  &::before {
    left: 0;
    transform: scale(-1, 1);
  }
  &::after {
    right: 0;
  }

  &__title {
    font-family: 'DragonSlapper', sans-serif;
    font-weight: 400;
    font-size: rem(40);
    text-transform: uppercase;
    text-align: center;
    color: #fff;

    @media (max-width: $tablet) {
      font-size: rem(24);
    }
  }
  &Body {
    margin: rem(60) 0 0;
    position: relative;

    @media (max-width: $tablet) {
      margin: rem(40) 0 0;
      overflow: hidden;
      height: rem(560);

      &::before,
      &::after {
        content: '';
        position: absolute;
        z-index: 8;
        display: block;
        left: 0;
        right: 0;
        width: 100%;
        height: rem(70);
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 100%
        );
      }

      &::before {
        top: 0;
      }
      &::after {
        bottom: 0;
        transform: scale(1, -1);
      }
    }

    &__slider {
      // position: absolute;
      // left: 50%;
      // transform: translate(-50%, 0);
      margin: 0 auto;

      width: rem(432);
      height: rem(574);
      // height: 320px;
    }
    &__card {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__marquee {
      position: relative;
      z-index: 0;
      height: 100%;
      margin: rem(1);

      @media (max-width: $tablet) {
        display: flex;
        gap: rem(4);
        justify-content: center;
      }

      &Row {
        position: relative;
        z-index: 0;

        @media (max-width: $tablet) {
          height: 100%;
          // width: rem(400) !important;
          // height: rem(138) !important;
          // flex: 0 0 auto;
          overflow: hidden;
        }

        &:not(:first-child) {
          margin: rem(24) 0 0;

          @media (max-width: $tablet) {
            margin: 0;

            .content {
              // animation: marquee-ttb 10s infinite linear 1s;
              animation: marquee-ttb 16s infinite linear;
            }
          }
        }

        img {
          @media (max-width: $tablet) {
            width: rem(138);
            object-fit: contain;
          }
        }

        .content {
          display: flex;
          gap: rem(24);
          padding: 0 rem(24) 0 0;

          @media (max-width: $tablet) {
            flex-direction: column;
            padding: rem(3) 0 0;
            gap: rem(3);
            animation: marquee-ttb 17s infinite linear;

            img {
              flex: 0 0 auto;
            }
          }
        }
      }
    }
  }
  &Bottom {
    margin: rem(40) 0 0;

    &__text {
      font-weight: 400;
      font-size: rem(20);
      line-height: normal;
      text-align: center;
      color: #bfbfbf;

      @media (max-width: $tablet) {
        font-size: rem(12);
        max-width: rem(240);
        margin: 0 auto;
        br {
          display: none;
        }
      }
    }

    &__button {
      position: relative;
      z-index: 1;
      margin: rem(46) auto 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: rem(260);
      height: rem(60);

      font-family: 'BL Melody', sans-serif;
      font-weight: 500;
      font-size: rem(20);
      text-transform: uppercase;
      text-align: center;
      color: #fff;

      @media (max-width: $tablet) {
        margin: rem(30) auto 0;
        width: rem(174);
        height: rem(38);
        font-size: rem(12);
      }

      .bg {
        position: absolute;
        z-index: -1;
        inset: 0;

        svg {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

@keyframes marquee-ttb {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-50%);
  }
}
