@use 'sass:math';
@import '/src/scss/mixins';
@import '/src/scss/style';

.game {
  margin: rem(230) 0 rem(60);

  @media (max-width: $tablet) {
    margin: rem(150) 0 0;
  }

  &Body {
    position: relative;

    &__card {
      position: relative;
      z-index: 2;
      max-width: rem(440);

      @media (max-width: $tablet) {
        max-width: rem(218);
      }

      &:nth-child(2) {
        text-align: right;
        max-width: rem(530);
        margin: rem(100) 0 0 auto;

        @media (max-width: $tablet) {
          margin: rem(40) 0 0 auto;
          max-width: rem(218);
        }
      }

      &Title {
        font-family: 'DragonSlapper', sans-serif;
        font-weight: 400;
        font-size: rem(40);
        text-transform: uppercase;
        color: #fff;
        line-height: normal;

        @media (max-width: $tablet) {
          font-size: rem(24);
        }
      }
      &Text {
        margin: rem(25) 0 0;
        display: flex;
        flex-direction: column;
        gap: rem(15);

        font-family: 'BL Melody', sans-serif;
        font-weight: 400;
        font-size: rem(20);
        color: #bfbfbf;

        @media (max-width: $tablet) {
          margin: rem(15) 0 0;
          font-size: rem(12);
        }
      }
    }
    &__img {
      position: absolute;
      z-index: 1;
      // top: 50%;
      left: 50%;
      transform: translate(-50%, 0);
      // width: rem(1100);
      top: rem(-100);
      bottom: rem(-60);
      width: 100%;

      @media (max-width: $tablet) {
        top: rem(-120);
        width: calc(100% + rem(32));
        bottom: auto;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
