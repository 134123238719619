@use 'sass:math';
@import '/src/scss/mixins';
@import '/src/scss/style.scss';

.modal {
  position: fixed;
  z-index: 10;
  inset: 0;
  margin: 0 rem(20);

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: $tablet) {
    margin: 0;
  }

  &Body {
    position: relative;
    width: 100%;
    max-width: rem(1460);
    height: rem(822);
    // background: #000;
    // background: red;

    overflow: hidden;
    padding: rem(1);

    @media (max-width: $tablet) {
      max-width: calc(100% - rem(30));
      height: calc(100vh - rem(200));
    }

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      inset: rem(0);
      background: linear-gradient(
        180deg,
        rgba(105, 105, 105, 1) 0%,
        rgba(0, 0, 0, 0) 98%
      );
    }
    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      inset: rem(1);
      background: #000;
    }

    &__close {
      cursor: pointer;
      position: absolute;
      z-index: 1;
      top: rem(8);
      left: rem(8);
      width: rem(52);
      height: rem(52);
      background: rgba(236, 170, 43, 0.48);
      display: flex;
      justify-content: center;
      align-items: center;

      transition: all $time $tr;

      &:hover {
        opacity: 0.7;
      }

      svg {
        width: rem(18);
        height: rem(18);
      }
    }
    &__img {
      position: relative;
      z-index: 0;
      height: 100%;
      margin: rem(1);

      @media (max-width: $tablet) {
        display: flex;
        gap: rem(4);
        justify-content: center;
      }

      img,
      video {
        width: 100%;
        height: 100%;
        // object-fit: cover;
      }

      &Row {
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          height: rem(820);
          object-fit: contain;

          @media (max-width: $tablet) {
            height: rem(184);
          }
        }
      }
    }
    &__buy {
      cursor: pointer;
      position: absolute;
      z-index: 1;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: rem(30);
      width: rem(260);
      height: rem(60);

      display: flex;
      justify-content: center;
      align-items: center;

      font-family: 'Open Sans', sans-serif;
      font-weight: 600;
      font-size: rem(16);
      text-transform: uppercase;
      text-align: center;
      color: #fff;

      transition: all $time $tr;

      @media (max-width: $tablet) {
        width: rem(208);
        font-size: rem(14);
        height: rem(48);
      }

      &:hover {
        opacity: 0.7;
      }

      svg {
        position: absolute;
        z-index: -1;
        inset: 0;
        width: 100%;
        height: 100%;
      }
    }
    &__row {
      position: relative;
      z-index: 0;

      @media (max-width: $tablet) {
        height: 100%;
        // width: rem(400) !important;
        // height: rem(138) !important;
        // flex: 0 0 auto;
        overflow: hidden;
      }

      &:not(:first-child) {
        margin: rem(24) 0 0;

        @media (max-width: $tablet) {
          margin: 0;

          .modalBody__rowContent {
            // animation: marquee-ttb 10s infinite linear 1s;
            animation: marquee-ttb 16s infinite linear;
          }
        }
      }

      img {
        @media (max-width: $tablet) {
          width: rem(138);
          object-fit: contain;
        }
      }

      &Content {
        display: flex;
        gap: rem(24);
        padding: 0 rem(24) 0 0;

        @media (max-width: $tablet) {
          flex-direction: column;
          padding: rem(3) 0 0;
          gap: rem(3);
          animation: marquee-ttb 17s infinite linear;

          img {
            flex: 0 0 auto;
          }
        }
      }
    }
  }
}

@keyframes marquee-ttb {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-50%);
  }
}
