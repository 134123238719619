@use 'sass:math';
@import '/src/scss/mixins';
@import '/src/scss/style.scss';

.gallery {
  position: relative;
  padding: rem(30) 0;
  margin: rem(230) 0 0;

  @media (max-width: $tablet) {
    margin: rem(50) 0 0;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100vw;
    height: rem(1);
    background: linear-gradient(
      90deg,
      rgba(105, 105, 105, 0) 0%,
      #696969 50%,
      rgba(105, 105, 105, 0) 100%
    );
  }

  &::before {
    top: 0;
  }
  &::after {
    bottom: 0;
  }

  &Body {
    position: relative;

    @media (max-width: $tablet) {
      display: flex;
      justify-content: center;
      height: rem(400);
      gap: rem(3);
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #000 100%);
      width: rem(474);
      height: 100%;
      top: 0;

      @media (max-width: $tablet) {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
        width: 100%;
        height: rem(66);
        left: 0;
        right: 0;
        top: auto;
      }
    }

    &::before {
      left: 0;
      transform: scale(-1, 1);
      @media (max-width: $tablet) {
        transform: scale(1, -1);
        top: 0;
      }
    }

    &::after {
      right: 0;
      @media (max-width: $tablet) {
        bottom: 0;
      }
    }

    &__row {
      position: relative;
      z-index: 0;

      @media (max-width: $tablet) {
        height: 100%;
        // width: rem(400) !important;
        // height: rem(138) !important;
        // flex: 0 0 auto;
        overflow: hidden;
      }

      &:not(:first-child) {
        margin: rem(24) 0 0;

        @media (max-width: $tablet) {
          margin: 0;

          .galleryBody__rowContent {
            // animation: marquee-ttb 10s infinite linear 1s;
            animation: marquee-ttb 16s infinite linear;
          }
        }
      }

      img {
        width: rem(400);
        height: rem(225);
        object-fit: cover;

        @media (max-width: $tablet) {
          width: rem(138);
          height: rem(78);
        }
      }

      &Content {
        display: flex;
        gap: rem(24);
        padding: 0 rem(24) 0 0;

        @media (max-width: $tablet) {
          flex-direction: column;
          padding: rem(3) 0 0;
          gap: rem(3);
          animation: marquee-ttb 17s infinite linear;

          img {
            flex: 0 0 auto;
          }
        }
      }
    }
  }
}

@keyframes marquee-ttb {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-50%);
  }
}
