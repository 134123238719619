@use 'sass:math';
@import '/src/scss/mixins';
@import '/src/scss/style';

.footer {
  position: relative;
  padding: rem(90) 0 rem(120);
  margin: rem(170) 0 0;

  @media (max-width: $tablet) {
    margin: rem(100) 0 0;
    padding: rem(15) 0 rem(30);
  }

  &::after {
    content: '';

    position: absolute;
    width: 100vw;
    top: 0;
    height: rem(1);
    background: linear-gradient(
      90deg,
      rgba(105, 105, 105, 0) 0%,
      #696969 50%,
      rgba(105, 105, 105, 0) 100%
    );
  }

  &Body {
    display: flex;
    // justify-content: space-between;

    @media (max-width: $tablet) {
      flex-direction: column;
      align-items: center;
    }

    &__info {
      margin: 0 auto 0 0;

      @media (max-width: $tablet) {
        margin: 0;
        width: 100%;
      }

      &Logo {
        width: rem(156);

        @media (max-width: $tablet) {
          width: rem(82);
          margin: 0 auto;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      &Text {
        margin: rem(28) 0 0;

        font-family: 'DragonSlapper', sans-serif;
        font-weight: 400;
        font-size: rem(32);
        text-transform: uppercase;
        color: #fff;
        line-height: normal;

        @media (max-width: $tablet) {
          margin: rem(10) 0 0;
          font-size: rem(12);
          text-align: center;

          br {
            display: none;
          }
        }
      }
    }
    &__col {
      @media (max-width: $tablet) {
        display: none;
      }

      &:not(:first-child) {
        margin: 0 0 0 rem(160);
      }

      &Title {
        font-family: 'BL Melody', sans-serif;
        font-weight: 500;
        font-size: rem(24);
        text-transform: uppercase;
        color: #fff;
      }
      ul {
        margin: rem(24) 0 0;
        display: flex;
        flex-direction: column;
        gap: rem(12);
        li {
          a {
            font-family: 'BL Melody', sans-serif;
            font-weight: 400;
            font-size: rem(20);
            text-transform: uppercase;
            text-align: center;
            color: #bfbfbf;
            transition: all $time $tr;
            white-space: nowrap;

            &:hover {
              color: #fff;
            }
          }
        }
      }
    }
    &__social {
      margin: rem(20) 0 0;
      display: flex;
      gap: rem(20);
      justify-content: center;

      a {
        width: rem(20);
        height: rem(20);

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
    &__links {
      margin: rem(20) 0 0;
      display: flex;
      gap: rem(15);
      justify-content: center;

      &Item {
        font-family: 'BL Melody', sans-serif;
        font-weight: 400;
        font-size: 8px;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.88);
      }
    }
  }
}

// .footer {
//   position: relative;
//   padding: rem(44) 0 rem(60);

//   @media (max-width: $tablet) {
//     padding: rem(15) 0 rem(30);
//   }

// &::after {
//   content: '';
//   position: absolute;
//   width: 100vw;
//   top: 0;
//   height: rem(1);
//   background: linear-gradient(
//     90deg,
//     rgba(105, 105, 105, 0) 0%,
//     #696969 50%,
//     rgba(105, 105, 105, 0) 100%
//   );
// }

//   &Body {
//     display: flex;
//     flex-direction: column;
//     align-items: center;

//     &__logo {
//       width: rem(156);
//       height: rem(60);

//       @media (max-width: $tablet) {
//         width: rem(82);
//         height: rem(32);
//       }

//       img {
//         width: 100%;
//         height: 100%;
//         object-fit: contain;
//       }
//     }
//     &__text {
//       font-family: 'IM FELL French Canon SC', sans-serif;
//       margin: rem(24) 0 0;
//       font-weight: 400;
//       font-size: rem(32);
//       text-align: center;
//       color: #fff;

//       @media (max-width: $tablet) {
//         margin: rem(20) 0 0;
//         font-size: rem(12);
//       }
//     }
//     &__social {
//       display: flex;
//       margin: rem(24) 0 0;
//       gap: rem(48);

//       @media (max-width: $tablet) {
//         margin: rem(20) 0 0;
//         gap: rem(20);
//       }

//       a {
//       }
//     }
//     &__git {
//       margin: rem(24) 0 0;
//       font-family: 'Open Sans', sans-serif;
//       font-weight: 400;
//       font-size: rem(16);
//       color: rgba(255, 255, 255, 0.88);

//       @media (max-width: $tablet) {
//         margin: rem(20) 0 0;
//         font-size: rem(12);
//       }
//     }
//     &__cr {
//       font-family: 'Open Sans', sans-serif;
//       margin: rem(54) 0 0;
//       font-weight: 400;
//       font-size: rem(16);
//       color: rgba(255, 255, 255, 0.88);
//       @media (max-width: $tablet) {
//         margin: rem(20) 0 0;
//         font-size: rem(10);
//       }
//     }
//   }
// }
