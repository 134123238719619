// ===============
// colors palette
// ===============

$colors: (
  'main-white': #fff,
);

//========================================================================================================================================================

:root {
  @if $colors {
    @each $key, $value in $colors {
      --color-#{$key}: #{$value};
      --color-#{$key}--rgb: #{hextorgb($value)};
    }
  }

  --font-primary: #{$fontFamily};
}
