@font-face {
  font-family: 'IM Fell French Canon SC';
  font-display: swap;
  src: url('../fonts/IMFellFrenchCanonSC-Regular.woff2') format('woff2'),
    url('../fonts/IMFellFrenchCanonSC-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  src: url('../fonts/OpenSans-Bold.woff2') format('woff2'),
    url('../fonts/OpenSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  src: url('../fonts/OpenSans-Regular.woff2') format('woff2'),
    url('../fonts/OpenSans-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  src: url('../fonts/OpenSans-SemiBold.woff2') format('woff2'),
    url('../fonts/OpenSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: BL Melody;
  font-display: swap;
  src: url('../fonts/BLMelody-Bold.woff2') format('woff2'),
    url('../fonts/BLMelody-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: BL Melody;
  font-display: swap;
  src: url('../fonts/BLMelody-Book.woff2') format('woff2'),
    url('../fonts/BLMelody-Book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: BL Melody;
  font-display: swap;
  src: url('../fonts/BLMelody-Medium.woff2') format('woff2'),
    url('../fonts/BLMelody-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: BL Melody;
  font-display: swap;
  src: url('../fonts/BLMelody-Regular.woff2') format('woff2'),
    url('../fonts/BLMelody-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: DragonSlapper;
  font-display: swap;
  src: url('../fonts/Dragonslapper.woff2') format('woff2'),
    url('../fonts/Dragonslapper.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
