@use 'sass:math';
@import '/src/scss/mixins';
@import '/src/scss/style.scss';

.application {
  margin: rem(160) 0 0;
  padding: 0 0 rem(170);

  @media (max-width: $tablet) {
    margin: rem(70) 0 0;
    padding: 0;
  }

  &__title {
    font-family: 'DragonSlapper', sans-serif;
    font-weight: 400;
    font-size: rem(40);
    line-height: normal;
    text-align: center;
    color: #fff;

    @media (max-width: $tablet) {
      font-size: rem(24);
    }
  }

  &Body {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: rem(18);
    margin: rem(100) 0 0;

    @media (max-width: $tablet) {
      margin: rem(24) 0 0;
      padding: 0 rem(24);
      gap: rem(10);
    }

    &__img {
      position: absolute;
      z-index: -1;
      left: rem(-50);
      top: rem(-200);
      width: rem(1930);

      // bottom: rem(-170);
      @media (max-width: $tablet) {
        position: relative;
        margin: 0 0 rem(15);
        z-index: 1;
        left: auto;
        top: auto;
        width: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__card {
      position: relative;
      max-width: rem(474);
      min-height: rem(220);
      padding: rem(24);
      display: flex;
      gap: rem(5);
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media (max-width: $tablet) {
        min-height: rem(130);
        max-width: 100%;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
      }

      &::before {
        z-index: -1;
        inset: 0;
        background: linear-gradient(
          270deg,
          rgba(#696969, 0) 0%,
          rgba(#696969, 1) 100%
        );
        @media (max-width: $tablet) {
          background: linear-gradient(
            0deg,
            rgba(#696969, 0) 0%,
            rgba(#696969, 1) 100%
          );
        }
      }

      &::after {
        z-index: 0;
        top: rem(1);
        left: rem(1);
        right: rem(0);
        bottom: rem(1);
        background-color: #000;

        @media (max-width: $tablet) {
          right: rem(1);
          bottom: 0;
        }
      }

      &Value {
        position: relative;
        z-index: 1;
        font-family: 'DragonSlapper', sans-serif;
        font-weight: 400;
        font-size: rem(40);
        text-transform: uppercase;
        color: #fff;

        @media (max-width: $tablet) {
          font-size: rem(24);
        }
      }
      &Text {
        position: relative;
        z-index: 1;
        font-family: 'BL Melody', sans-serif;
        font-weight: 400;
        font-size: rem(20);
        text-align: center;
        color: #bfbfbf;

        @media (max-width: $tablet) {
          font-size: rem(12);
        }
      }
    }
  }
}
