@use 'sass:math';
@import '/src/scss/mixins';
@import '/src/scss/style.scss';

.join {
  position: relative;
  // margin: rem(30) 0 0;

  @media (max-width: $tablet) {
    padding: rem(40) 0 rem(120);
  }

  // &::after {
  //   content: '';
  //   position: absolute;
  //   width: 100vw;
  //   top: 0;
  //   height: rem(1);
  //   background: linear-gradient(
  //     90deg,
  //     rgba(105, 105, 105, 0) 0%,
  //     #696969 50%,
  //     rgba(105, 105, 105, 0) 100%
  //   );
  // }

  &Body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: $tablet) {
      min-height: auto;
    }

    &__text {
      font-family: 'IM FELL French Canon SC', sans-serif;
      font-weight: 400;
      font-size: rem(32);
      text-align: center;
      color: #fff;

      @media (max-width: $tablet) {
        font-size: rem(18);
      }
    }
    &__link {
      cursor: pointer;
      position: relative;
      margin: rem(30) auto 0;
      width: rem(260);
      height: rem(60);

      display: flex;
      justify-content: center;
      align-items: center;

      font-family: 'Open Sans', sans-serif;
      font-weight: 600;
      font-size: rem(16);
      text-transform: uppercase;
      text-align: center;
      color: #fff;

      @media (max-width: $tablet) {
        margin: rem(20) auto 0;
        width: rem(200);
        height: rem(46);
        font-size: rem(14);
      }

      &:hover {
        svg {
          opacity: 0.7;
        }
      }

      svg {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        transition: all $time $tr;
      }
    }
  }
}
